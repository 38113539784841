import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { on } from "@ember/modifier";
import { action } from "@ember/object";
import { modifier as modifierFn } from "ember-modifier";
import { eq } from "truth-helpers";
import DButton from "discourse/components/d-button";
import concatClass from "discourse/helpers/concat-class";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
const TYPES = {
  text: "text",
  password: "password"
};
export default class FKControlInput extends Component {
  static controlType = "password";
  static #_ = (() => dt7948.g(this.prototype, "type", [tracked], function () {
    return TYPES.password;
  }))();
  #type = (() => (dt7948.i(this, "type"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "isFocused", [tracked], function () {
    return false;
  }))();
  #isFocused = (() => (dt7948.i(this, "isFocused"), void 0))();
  focusState = (() => modifierFn(element1 => {
    const focusInHandler1 = () => {
      this.isFocused = true;
    };
    const focusOutHandler1 = () => {
      this.isFocused = false;
    };
    element1.addEventListener("focusin", focusInHandler1);
    element1.addEventListener("focusout", focusOutHandler1);
    return () => {
      element1.removeEventListener("focusin", focusInHandler1);
      element1.removeEventListener("focusout", focusOutHandler1);
    };
  }))();
  get iconForType() {
    return this.type === TYPES.password ? "far-eye" : "far-eye-slash";
  }
  handleInput(event1) {
    const value1 = event1.target.value === "" ? undefined : event1.target.value;
    this.args.field.set(value1);
  }
  static #_3 = (() => dt7948.n(this.prototype, "handleInput", [action]))();
  toggleVisibility() {
    this.type = this.type === TYPES.password ? TYPES.text : TYPES.password;
  }
  static #_4 = (() => dt7948.n(this.prototype, "toggleVisibility", [action]))();
  static #_5 = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      <div
        class={{concatClass
          "form-kit__control-password-wrapper"
          (if this.isFocused "--focused")
        }}
      >
        <input
          type={{this.type}}
          value={{@value}}
          class="form-kit__control-password"
          disabled={{@field.disabled}}
          ...attributes
          {{on "input" this.handleInput}}
          {{this.focusState}}
        />
  
        <DButton
          class="btn-transparent form-kit__control-password-toggle"
          @action={{this.toggleVisibility}}
          @icon={{this.iconForType}}
          role="switch"
          aria-checked={{eq this.type TYPES.text}}
        />
      </div>
    
  */
  {
    "id": "lJ3ycdwM",
    "block": "[[[1,\"\\n    \"],[10,0],[15,0,[28,[32,0],[\"form-kit__control-password-wrapper\",[52,[30,0,[\"isFocused\"]],\"--focused\"]],null]],[12],[1,\"\\n      \"],[11,\"input\"],[16,4,[30,0,[\"type\"]]],[16,2,[30,1]],[24,0,\"form-kit__control-password\"],[16,\"disabled\",[30,2,[\"disabled\"]]],[17,3],[4,[32,1],[\"input\",[30,0,[\"handleInput\"]]],null],[4,[30,0,[\"focusState\"]],null,null],[12],[13],[1,\"\\n\\n      \"],[8,[32,2],[[24,0,\"btn-transparent form-kit__control-password-toggle\"],[24,\"role\",\"switch\"],[16,\"aria-checked\",[28,[32,3],[[30,0,[\"type\"]],[32,4,[\"text\"]]],null]]],[[\"@action\",\"@icon\"],[[30,0,[\"toggleVisibility\"]],[30,0,[\"iconForType\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@value\",\"@field\",\"&attrs\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/form-kit/components/fk/control/password.js",
    "scope": () => [concatClass, on, DButton, eq, TYPES],
    "isStrictMode": true
  }), this))();
}